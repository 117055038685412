@import "_globals.less";

#magazine-intro {
    width: 100%;
    margin-bottom: 5px;
}

#magazine-intro-wrapper {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 15px;
}

#additional-magazines-container,
#additional-books-container {
    margin-top: 15px;
}

.default-books {
    margin-top: 15px;

    + .default-books {
        border-top: 1px solid @c-lightblue-250;
        padding-top: 15px;
    }
}

.tx-dreipc-publications-manager .disabled {
    display: none;
}

#additional-magazines-options,
#additional-books-options {
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;

    select {
        flex: 1 1 auto;
        width: 0; // prevent select from overflowing
    }
}

#additional-magazines-list,
#additional-books-list {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
}

#price {
    font-size: 15px;
    font-weight: 500;

    p:last-child {
        width: 59%;
    }
}

.booking-type {

    p {
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 20px;
    }

    .booking-type-buttons {
        display: flex;
        justify-content: space-between;
        padding: 7px 0 20px 0;

        &.disabled {
            #change-to-ebook,
            #change-to-bundle {
                background-color: @c-gray-300;
                border: 1px solid @c-gray-300;
                cursor: unset;
                transition: none;

                &:hover {
                    color: @c-darkgray-400;
                }
            }
        }

        button {
            background-color: unset;
            border: 1px solid @c-darkgray-400;
            color: @c-darkgray-400;
            min-width: 150px;
            transition: all ease 0.5s;

            &.active-type {
                background-color: @c-blue-200;
                border: 1px solid @c-blue-200;
                color: @c-white;
            }

            &:hover {
                background-color: @c-blue-200;
                border-color: @c-blue-200;
                color: @c-white;
            }
        }
    }
}

.booking-type-ebook-text,
.booking-type-bundle-text,
.booking-type-book-text,
#ebooks,
#add-ebooks,
#bundle,
#add-bundle {
    display: none;
}

#additional-books-container #change-book-type {
    flex: 0.22;
}

.add-default-books-form button {
    float: right;
    min-width: 150px;
}

#default-books-list {
    list-style: none;
    margin: 0;
    padding: 0;

    .default-book-quantity,
    .default-ebook-quantity,
    .default-bundle-quantity {
        box-sizing: border-box;
        width: 100px;
    }
}

.magazine-quantity,
.additional-magazine,
.additional-books,
.default-books {
    position: relative;
    color: @c-darkgray-400;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 15px;
}

.additional-magazine {
    margin-top: 15px;

    + .additional-magazine {
        border-top: 1px solid @c-lightblue-250;
        padding-top: 15px;
    }

    label {
        width: auto;
        display: inline-block;
        vertical-align: top;
    }

    input[type="number"] {
        width: 100px;
    }

    button {
        background-color: #c9282d;

        &:hover {
            background-color: rgba(201, 40, 45, 0.5);
        }
    }

}

.additional-books {
    margin-top: 15px;

    + .additional-books {
        border-top: 1px solid @c-lightblue-250;
        padding-top: 15px;
    }

    label {
        width: auto;
        display: inline-block;
        vertical-align: top;
    }

    input[type="number"] {
        width: 100px;
    }

    button {
        background-color: #c9282d;

        &:hover {
            background-color: rgba(201, 40, 45, 0.5);
        }
    }

}

.magazine-title,
.additional-magazine-title,
.book-title,
.additional-book-title,
.default-book-title {
    flex: 1 1 auto;
    margin-bottom: 0;
}

.additional-magazine-delete,
.additional-book-delete,
.default-book-delete,
.additional-ebook-delete,
.additional-bundle-delete {
    background-color: @c-white;
    border-radius: 50%;
    color: @c-blue-200;
    cursor: pointer;
    display: block;
    height: 20px;
    width: 20px;
    transition: all 200ms;

    &:hover,
    &:focus {
        color: @c-lightblue-200;
    }

    &:after {
        content: 'i';
        display: block;
        font-family: 'Iconfont';
        font-size: 20px;
        line-height: 1;
        width: 20px;
        height: 20px;
    }
}

.magazine-quantity input#quantity,
.book-quantity input#quantity {
    width: 100px;
    margin-right: 35px;
}

#price {
    p:last-child {
        text-align: right;
    }
}

p.bookmark-order {
    margin-top: 10px;
    background-color: #d8ecfe;
    padding: 10px;

    span {
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;
        display: block;
    }
}

.tx-dreipc-publications-manager {
    .form {
        input:focus-visible {
            outline: none;
        }
    }
    strong {
        &.ebook-teaser {
            color: #EEAD0E;
        }
        &.book-title {
            color: @c-blue-200;
            font-size: 17px;
        }
    }
    h3.order-intro {
        font-size: 25px;
        line-height: unset;
    }
}


@media screen and (max-width: 1300px) {
    .booking-type-buttons {
        flex-direction: column;

        button {
            margin-top: 10px;

            &:first-child {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #additional-magazines select,
    #additional-books select {
        max-width: 80%;
    }
}

@media screen and (max-width: 460px) {
    #additional-magazines select,
    #additional-books select {
        max-width: 100%;
    }
}