#magazine-intro {
  width: 100%;
  margin-bottom: 5px;
}
#magazine-intro-wrapper {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 15px;
}
#additional-magazines-container,
#additional-books-container {
  margin-top: 15px;
}
.default-books {
  margin-top: 15px;
}
.default-books + .default-books {
  border-top: 1px solid #B7D2E8;
  padding-top: 15px;
}
.tx-dreipc-publications-manager .disabled {
  display: none;
}
#additional-magazines-options,
#additional-books-options {
  display: flex;
  flex-flow: row nowrap;
  gap: 15px;
}
#additional-magazines-options select,
#additional-books-options select {
  flex: 1 1 auto;
  width: 0;
}
#additional-magazines-list,
#additional-books-list {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}
#price {
  font-size: 15px;
  font-weight: 500;
}
#price p:last-child {
  width: 59%;
}
.booking-type p {
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 20px;
}
.booking-type .booking-type-buttons {
  display: flex;
  justify-content: space-between;
  padding: 7px 0 20px 0;
}
.booking-type .booking-type-buttons.disabled #change-to-ebook,
.booking-type .booking-type-buttons.disabled #change-to-bundle {
  background-color: #7a7a7a;
  border: 1px solid #7a7a7a;
  cursor: unset;
  transition: none;
}
.booking-type .booking-type-buttons.disabled #change-to-ebook:hover,
.booking-type .booking-type-buttons.disabled #change-to-bundle:hover {
  color: #424242;
}
.booking-type .booking-type-buttons button {
  background-color: unset;
  border: 1px solid #424242;
  color: #424242;
  min-width: 150px;
  transition: all ease 0.5s;
}
.booking-type .booking-type-buttons button.active-type {
  background-color: #0080bc;
  border: 1px solid #0080bc;
  color: #ffffff;
}
.booking-type .booking-type-buttons button:hover {
  background-color: #0080bc;
  border-color: #0080bc;
  color: #ffffff;
}
.booking-type-ebook-text,
.booking-type-bundle-text,
.booking-type-book-text,
#ebooks,
#add-ebooks,
#bundle,
#add-bundle {
  display: none;
}
#additional-books-container #change-book-type {
  flex: 0.22;
}
.add-default-books-form button {
  float: right;
  min-width: 150px;
}
#default-books-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
#default-books-list .default-book-quantity,
#default-books-list .default-ebook-quantity,
#default-books-list .default-bundle-quantity {
  box-sizing: border-box;
  width: 100px;
}
.magazine-quantity,
.additional-magazine,
.additional-books,
.default-books {
  position: relative;
  color: #424242;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 15px;
}
.additional-magazine {
  margin-top: 15px;
}
.additional-magazine + .additional-magazine {
  border-top: 1px solid #B7D2E8;
  padding-top: 15px;
}
.additional-magazine label {
  width: auto;
  display: inline-block;
  vertical-align: top;
}
.additional-magazine input[type="number"] {
  width: 100px;
}
.additional-magazine button {
  background-color: #c9282d;
}
.additional-magazine button:hover {
  background-color: rgba(201, 40, 45, 0.5);
}
.additional-books {
  margin-top: 15px;
}
.additional-books + .additional-books {
  border-top: 1px solid #B7D2E8;
  padding-top: 15px;
}
.additional-books label {
  width: auto;
  display: inline-block;
  vertical-align: top;
}
.additional-books input[type="number"] {
  width: 100px;
}
.additional-books button {
  background-color: #c9282d;
}
.additional-books button:hover {
  background-color: rgba(201, 40, 45, 0.5);
}
.magazine-title,
.additional-magazine-title,
.book-title,
.additional-book-title,
.default-book-title {
  flex: 1 1 auto;
  margin-bottom: 0;
}
.additional-magazine-delete,
.additional-book-delete,
.default-book-delete,
.additional-ebook-delete,
.additional-bundle-delete {
  background-color: #ffffff;
  border-radius: 50%;
  color: #0080bc;
  cursor: pointer;
  display: block;
  height: 20px;
  width: 20px;
  transition: all 200ms;
}
.additional-magazine-delete:hover,
.additional-book-delete:hover,
.default-book-delete:hover,
.additional-ebook-delete:hover,
.additional-bundle-delete:hover,
.additional-magazine-delete:focus,
.additional-book-delete:focus,
.default-book-delete:focus,
.additional-ebook-delete:focus,
.additional-bundle-delete:focus {
  color: #83b0d4;
}
.additional-magazine-delete:after,
.additional-book-delete:after,
.default-book-delete:after,
.additional-ebook-delete:after,
.additional-bundle-delete:after {
  content: 'i';
  display: block;
  font-family: 'Iconfont';
  font-size: 20px;
  line-height: 1;
  width: 20px;
  height: 20px;
}
.magazine-quantity input#quantity,
.book-quantity input#quantity {
  width: 100px;
  margin-right: 35px;
}
#price p:last-child {
  text-align: right;
}
p.bookmark-order {
  margin-top: 10px;
  background-color: #d8ecfe;
  padding: 10px;
}
p.bookmark-order span {
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  display: block;
}
.tx-dreipc-publications-manager .form input:focus-visible {
  outline: none;
}
.tx-dreipc-publications-manager strong.ebook-teaser {
  color: #EEAD0E;
}
.tx-dreipc-publications-manager strong.book-title {
  color: #0080bc;
  font-size: 17px;
}
.tx-dreipc-publications-manager h3.order-intro {
  font-size: 25px;
  line-height: unset;
}
@media screen and (max-width: 1300px) {
  .booking-type-buttons {
    flex-direction: column;
  }
  .booking-type-buttons button {
    margin-top: 10px;
  }
  .booking-type-buttons button:first-child {
    margin: 0;
  }
}
@media screen and (max-width: 1000px) {
  #additional-magazines select,
  #additional-books select {
    max-width: 80%;
  }
}
@media screen and (max-width: 460px) {
  #additional-magazines select,
  #additional-books select {
    max-width: 100%;
  }
}
